import { createContext, useContext } from 'react';

import { PageContext } from '@shared-types/pageContext';

const Context = createContext<PageContext | undefined>(undefined);

export const PageContextProvider = Context.Provider;

export const usePageContext = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('usePageContext must be used within a PageContextProvider');
    }

    return context;
};

export const useLocales = () => {
    const context = usePageContext();
    return context.translations;
};
