import React, { createContext, useContext, useState } from 'react';

import { User } from '@helpers/userAccountInformation';

type State = {
    isMobileSidebarVisible: boolean;
    setIsMobileSidebarVisible: (isMobileSidebarVisible: boolean) => void;

    DI: any;
    setDI: (value: any) => void;

    setCurrentUser: (currentUser: User | null) => void;
    currentUser: User | null;
};

const AppContext = createContext<State | undefined>(undefined);

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within a AppProvider');
    }
    return { ...context };
};

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
    const [isMobileSidebarVisible, setIsMobileSidebarVisible] = useState(false);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [DI, setDI] = useState({});

    return (
        <AppContext.Provider
            value={{
                isMobileSidebarVisible,
                setIsMobileSidebarVisible,
                setCurrentUser,
                currentUser,
                DI,
                setDI,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
