import React from 'react';

import { PageContextProvider } from '@hooks/usePageContext';
import { WrapPageElementBrowserArgs, WrapRootElementBrowserArgs } from 'gatsby';
import 'whatwg-fetch';

import { init as initConsent } from '@jimdo/consent-management';

import { PageContext } from '@shared-types/pageContext';

import { AppProvider } from './src/hooks/useAppContext';

initConsent();

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
    // React Context in Browser
    return <AppProvider>{element}</AppProvider>;
};

export const wrapPageElement = ({ element, props }: WrapPageElementBrowserArgs) => {
    return (
        <PageContextProvider value={props.pageContext as PageContext}>
            {element}
        </PageContextProvider>
    );
};
